// src/components/Tracker.js
import React, { useState, useEffect, useRef } from "react";
import NavigationBar from "./Navbar";
import { ToastContainer, toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { apiInstance, socket } from "../config/config";
import "../css/Tracker.css";

// Chart imports
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Tracker = () => {
  // Default today's date in YYYY-MM-DD format.
  const todayStr = new Date().toLocaleDateString("en-CA", {
    timeZone: "Asia/Kolkata",
  });

  // State variables
  const [trackingData, setTrackingData] = useState([]);
  const [appMapping, setAppMapping] = useState({});
  const [groupedData, setGroupedData] = useState([]);

  // Chart state
  const [chartLabels, setChartLabels] = useState([]);
  const [chartWorkData, setChartWorkData] = useState([]);
  const [chartProductiveData, setChartProductiveData] = useState([]);

  // Slider for Work / Productive time
  const [timeMode, setTimeMode] = useState("work");

  // Date filter state, defaulted to today's date
  const [startDate, setStartDate] = useState(todayStr);
  const [endDate, setEndDate] = useState(todayStr);

  // Overall summary
  const [overallWorkTime, setOverallWorkTime] = useState(0);
  const [overallProductiveTime, setOverallProductiveTime] = useState(0);

  const role = localStorage.getItem("role");

  // Control date filter panel visibility
  const [showDateFilter, setShowDateFilter] = useState(false);
  const filterRef = useRef(null);
  const toggleDateFilter = () => setShowDateFilter((prev) => !prev);

  // Admin: Artist dropdown filter state (outside date filter)
  const [artistList, setArtistList] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState("");

  // Display current filter (if any)
  const getFilterDisplay = () => {
    if (startDate && endDate) {
      return startDate === endDate
        ? `Filter: ${startDate}`
        : `Filter: ${startDate} - ${endDate}`;
    }
    return "";
  };

  // Helper: Format Date to "YYYY-MM-DD"
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Handle preset date ranges
  const handlePresetFilter = (preset) => {
    const today = new Date();
    let start, end;
    switch (preset) {
      case "today":
        start = new Date();
        end = new Date();
        break;
      case "yesterday":
        start = new Date();
        start.setDate(today.getDate() - 1);
        end = new Date(start);
        break;
      case "thisWeek": {
        const dayOfWeek = today.getDay();
        const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
        start = new Date(today);
        start.setDate(today.getDate() - diffToMonday);
        end = new Date();
        break;
      }
      case "last7Days":
        start = new Date();
        start.setDate(today.getDate() - 6);
        end = new Date();
        break;
      case "previousWeek": {
        const dayOfWeek = today.getDay();
        const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
        const mondayThisWeek = new Date(today);
        mondayThisWeek.setDate(today.getDate() - diffToMonday);
        start = new Date(mondayThisWeek);
        start.setDate(mondayThisWeek.getDate() - 7);
        end = new Date(start);
        end.setDate(start.getDate() + 6);
        break;
      }
      case "thisMonth":
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case "previousMonth":
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      default:
        return;
    }
    setStartDate(formatDate(start));
    setEndDate(formatDate(end));
  };

  // Helper: Format seconds into human-readable string
  const formatTime = (seconds) => {
    if (!seconds) return "0s";
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.round(seconds % 60);
    return `${hrs ? hrs + "h " : ""}${mins ? mins + "m " : ""}${secs}s`;
  };

  // Click outside handler for filter panel
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showDateFilter && filterRef.current && !filterRef.current.contains(event.target)) {
        setShowDateFilter(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDateFilter]);

  // Clear filter function
  const clearFilter = () => {
    setStartDate(todayStr);
    setEndDate(todayStr);
    if (role === "admin") {
      setSelectedArtist("");
    }
  };

  // Check JWT expiration
  const checkTokenExpiration = () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          toast.error("Session expired. Please log in again.");
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          localStorage.removeItem("role");
          window.location.href = "/";
          return false;
        }
        return true;
      } catch (error) {
        console.error("Error decoding token:", error);
        return false;
      }
    }
    return false;
  };

  // Generate array of dates between start and end (inclusive)
  const getDatesInRange = (startStr, endStr) => {
    if (!startStr || !endStr) return [];
    if (startStr > endStr) return [];
    const dates = [];
    let current = startStr;
    while (current <= endStr) {
      dates.push(current);
      const [yyyy, mm, dd] = current.split("-");
      const dateObj = new Date(+yyyy, +mm - 1, +dd);
      dateObj.setDate(dateObj.getDate() + 1);
      const newY = dateObj.getFullYear();
      const newM = String(dateObj.getMonth() + 1).padStart(2, "0");
      const newD = String(dateObj.getDate()).padStart(2, "0");
      current = `${newY}-${newM}-${newD}`;
    }
    return dates;
  };

  // Check if a date string is within the selected range
  const isWithinRange = (dateStr) => {
    if (!startDate && !endDate) return true;
    if (startDate && dateStr < startDate) return false;
    if (endDate && dateStr > endDate) return false;
    return true;
  };

  // ------------------ WebSocket Setup ------------------
  // We now use the imported socket from our config.
  useEffect(() => {
    socket.on("trackingDataUpdate", (newTrackingData) => {
      console.log("Received tracking update via WebSocket:", newTrackingData);
      setTrackingData(newTrackingData);
    });
    return () => {
      socket.off("trackingDataUpdate");
    };
  }, []);

  // ------------------ Data Fetching ------------------
  useEffect(() => {
    if (!checkTokenExpiration()) return;
    const fetchTrackingData = async () => {
      try {
        const endpoint = role === "admin" ? "/api/tracking/all" : "/api/tracking/artist";
        const response = await apiInstance.get(endpoint, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        console.log("Fetched tracking data:", response.data);
        setTrackingData(response.data);
      } catch (error) {
        console.error("Error fetching tracking data:", error);
        toast.error("Error fetching tracking data");
      }
    };
    fetchTrackingData();
  }, [role]);

  // Fetch App Mapping for all roles
  useEffect(() => {
    const fetchAppList = async () => {
      try {
        const response = await apiInstance.get("/api/applist", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        const mapping = {};
        response.data.forEach((app) => {
          mapping[app.appName] = app.appType;
        });
        setAppMapping(mapping);
      } catch (error) {
        console.error("Error fetching app list:", error);
        toast.error("Error fetching app list");
      }
    };
    fetchAppList();
  }, []);

  // For admin, fetch the list of artists (dropdown outside the date filter)
  useEffect(() => {
    console.log("Admin fetch useEffect triggered; role =", role);
    if (role !== "admin") {
      console.log("Current role is not admin. Skipping artist fetch.");
      return;
    }
    const fetchArtists = async () => {
      try {
        const response = await apiInstance.get("/api/auth/artists", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        console.log("Fetched artist list:", response.data);
        setArtistList(response.data);
      } catch (error) {
        console.error("Error fetching artist list:", error);
        toast.error("Error fetching artist list");
      }
    };
    fetchArtists();
  }, [role]);

  // Helper: For admin view, sort apps so that "productive" apps come first
  const sortArtistApps = (appsInput) => {
    let appsArr;
    if (Array.isArray(appsInput)) {
      appsArr = appsInput;
    } else if (typeof appsInput === "object" && appsInput !== null) {
      appsArr = Object.entries(appsInput).map(([appName, times]) => ({
        appName,
        ...times,
      }));
    } else {
      return [];
    }
    return appsArr.sort((a, b) => {
      const typeA = appMapping[a.appName] || "";
      const typeB = appMapping[b.appName] || "";
      if (typeA === typeB) return a.appName.localeCompare(b.appName);
      if (typeA === "productive") return -1;
      if (typeB === "productive") return 1;
      return a.appName.localeCompare(b.appName);
    });
  };

  // ------------------ Group and Aggregate Data ------------------
  useEffect(() => {
    if (trackingData.length === 0) return;
    console.log("Raw tracking data:", trackingData);
    if (role === "artist") {
      const loggedInArtist = localStorage.getItem("username");
      const artistTrackingData = trackingData.filter(
        (session) => session.username === loggedInArtist
      );
      const dailyAggregator = {};
      artistTrackingData.forEach((session) => {
        const dateStr = session.date;
        if (!dailyAggregator[dateStr]) {
          dailyAggregator[dateStr] = { workTime: 0, productiveTime: 0, apps: {} };
        }
        session.apps.forEach((app) => {
          dailyAggregator[dateStr].workTime += app.workingTime;
          if (appMapping[app.appName] === "productive") {
            dailyAggregator[dateStr].productiveTime += app.workingTime;
          }
          if (dailyAggregator[dateStr].apps[app.appName]) {
            dailyAggregator[dateStr].apps[app.appName].workingTime += app.workingTime;
            dailyAggregator[dateStr].apps[app.appName].idleTime += app.idleTime;
          } else {
            dailyAggregator[dateStr].apps[app.appName] = {
              workingTime: app.workingTime,
              idleTime: app.idleTime,
            };
          }
        });
      });

      // Use only the dates within the selected filter
      let filteredDates = startDate && endDate ? getDatesInRange(startDate, endDate) : Object.keys(dailyAggregator);
      // Ensure each date in the filter has an entry in dailyAggregator (default to zero)
      filteredDates.forEach((dateStr) => {
        if (!dailyAggregator[dateStr]) {
          dailyAggregator[dateStr] = { workTime: 0, productiveTime: 0, apps: {} };
        }
      });
      filteredDates.sort();

      let totalWork = 0;
      let totalProductive = 0;
      const grouped = filteredDates.map((dateStr) => {
        totalWork += dailyAggregator[dateStr].workTime;
        totalProductive += dailyAggregator[dateStr].productiveTime;
        return {
          date: dateStr,
          apps: Object.keys(dailyAggregator[dateStr].apps).map((appName) => ({
            appName,
            workingTime: dailyAggregator[dateStr].apps[appName].workingTime,
            idleTime: dailyAggregator[dateStr].apps[appName].idleTime,
          })),
        };
      });
      setOverallWorkTime(totalWork);
      setOverallProductiveTime(totalProductive);
      setChartLabels(filteredDates);
      setChartWorkData(filteredDates.map((d) => dailyAggregator[d].workTime / 3600));
      setChartProductiveData(filteredDates.map((d) => dailyAggregator[d].productiveTime / 3600));
      setGroupedData(grouped);
    } else if (role === "admin") {
      // Admin grouping: include username in each record.
      const adminData = selectedArtist
        ? trackingData.filter((record) => record.username === selectedArtist)
        : trackingData;
      const groupByArtistDate = {};
      const dailyAggregator = {};
      adminData.forEach((record) => {
        const key = `${record.username}-${record.date}`;
        if (!groupByArtistDate[key]) {
          groupByArtistDate[key] = {
            username: record.username,
            date: record.date,
            apps: {},
          };
        }
        (record.apps || []).forEach((app) => {
          if (!groupByArtistDate[key].apps[app.appName]) {
            groupByArtistDate[key].apps[app.appName] = { workingTime: 0, idleTime: 0 };
          }
          groupByArtistDate[key].apps[app.appName].workingTime += app.workingTime;
          groupByArtistDate[key].apps[app.appName].idleTime += app.idleTime;
          if (!dailyAggregator[record.date]) {
            dailyAggregator[record.date] = { workTime: 0, productiveTime: 0 };
          }
          dailyAggregator[record.date].workTime += app.workingTime;
          if (appMapping[app.appName] === "productive") {
            dailyAggregator[record.date].productiveTime += app.workingTime;
          }
        });
      });
      setGroupedData(Object.values(groupByArtistDate));
      const rawDates = Object.keys(dailyAggregator);
      let filteredDates = startDate && endDate ? getDatesInRange(startDate, endDate) : rawDates.sort();
      filteredDates = filteredDates.map((d) => d || "").filter((d) => d !== "");
      filteredDates.forEach((dateStr) => {
        if (!dailyAggregator[dateStr]) {
          dailyAggregator[dateStr] = { workTime: 0, productiveTime: 0 };
        }
      });
      filteredDates.sort();
      let sumWork = 0;
      let sumProd = 0;
      filteredDates.forEach((dateStr) => {
        sumWork += dailyAggregator[dateStr].workTime || 0;
        sumProd += dailyAggregator[dateStr].productiveTime || 0;
      });
      setOverallWorkTime(sumWork);
      setOverallProductiveTime(sumProd);
      setChartLabels(filteredDates);
      setChartWorkData(
        filteredDates.map((d) => (dailyAggregator[d] ? dailyAggregator[d].workTime : 0) / 3600)
      );
      setChartProductiveData(
        filteredDates.map((d) => (dailyAggregator[d] ? dailyAggregator[d].productiveTime : 0) / 3600)
      );
    }
  }, [role, trackingData, appMapping, startDate, endDate, selectedArtist]);

  // Filtering table data
  const filteredTableData =
    role === "admin"
      ? groupedData.filter((record) => {
          if (!isWithinRange(record.date)) return false;
          if (selectedArtist && record.username !== selectedArtist) return false;
          return true;
        })
      : groupedData.filter((record) => isWithinRange(record.date));

  useEffect(() => {
    console.log("Filtered table data:", filteredTableData);
  }, [filteredTableData]);

  // Chart configuration
  const barChartData = {
    labels: chartLabels,
    datasets: [
      {
        label: timeMode === "work" ? "Work Time (hr)" : "Productive Time (hr)",
        data: timeMode === "work" ? chartWorkData : chartProductiveData,
        backgroundColor: timeMode === "work" ? "#4BC0C0" : "#36A2EB",
      },
    ],
  };

  // Make sure to disable aspect ratio for all views (or conditionally, if you detect mobile in JS)
  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false, // This allows the chart to fill the container's height
    plugins: {
      legend: { display: true },
      tooltip: {
        callbacks: {
          label: (context) => {
            const seconds = context.raw * 3600;
            return formatTime(seconds);
          },
        },
      },
      title: { display: false },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: { display: true, text: "Hours" },
      },
      x: {
        title: { display: true, text: "Date" },
      },
    },
  };

  // --- Render Admin View ---
  // Now the admin view table includes three columns: Date, Artist, and Apps (Working / Idle)
  const renderAdminView = () => (
    <>
      <div className="filter-display" style={{ textAlign: "center", marginBottom: "10px" }}>
        {getFilterDisplay()}
      </div>
      {/* Artist filter outside date filter */}
      <div className="artist-filter" style={{ textAlign: "center", marginBottom: "10px" }}>
        <label>
          Artist:{" "}
          <select value={selectedArtist} onChange={(e) => setSelectedArtist(e.target.value)}>
            <option value="">All</option>
            {artistList.map((artist, index) => (
              <option key={index} value={artist.username}>
                {artist.username}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className="summary-boxes">
        <div className="summary-box">
          <h3>Work Time</h3>
          <p>{formatTime(overallWorkTime)}</p>
        </div>
        <div className="summary-box">
          <h3>Productive Time</h3>
          <p>{formatTime(overallProductiveTime)}</p>
        </div>
      </div>
      <div className="filter-toggle" style={{ textAlign: "center" }}>
        <button onClick={toggleDateFilter}>{showDateFilter ? "Filter –" : "Filter +"}</button>
        <button onClick={clearFilter} style={{ marginLeft: "10px" }}>
          Clear Filter
        </button>
      </div>
      {showDateFilter && (
        <div className="date-filter-container" ref={filterRef}>
          <div className="date-filter-inner" style={{ display: "flex" }}>
            {/* Calendar inputs */}
            <div className="date-pickers" style={{ flex: 1, paddingRight: "10px" }}>
              <label>
                Start Date:
                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              </label>
              <label>
                End Date:
                <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              </label>
            </div>
            {/* Preset options */}
            <div className="preset-list" style={{ flex: 1 }}>
              <ul style={{ listStyle: "none", padding: 0 }}>
                <li onClick={() => handlePresetFilter("today")}>Today</li>
                <li onClick={() => handlePresetFilter("yesterday")}>Yesterday</li>
                <li onClick={() => handlePresetFilter("thisWeek")}>This Week</li>
                <li onClick={() => handlePresetFilter("last7Days")}>Last 7 Days</li>
                <li onClick={() => handlePresetFilter("previousWeek")}>Previous Week</li>
                <li onClick={() => handlePresetFilter("thisMonth")}>This Month</li>
                <li onClick={() => handlePresetFilter("previousMonth")}>Previous Month</li>
              </ul>
            </div>
          </div>
        </div>
      )}
      <div className="slider-container">
        <input
          type="radio"
          id="workTime"
          name="timeToggle"
          value="work"
          checked={timeMode === "work"}
          onChange={() => setTimeMode("work")}
        />
        <label htmlFor="workTime">Work Time</label>
        <input
          type="radio"
          id="productiveTime"
          name="timeToggle"
          value="productive"
          checked={timeMode === "productive"}
          onChange={() => setTimeMode("productive")}
        />
        <label htmlFor="productiveTime">Productive</label>
        <span className="slider"></span>
      </div>
      <div className="chart-container">
        <Bar data={barChartData} options={barChartOptions} />
      </div>
      <table className="tracker-table">
        <colgroup>
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "60%" }} />
        </colgroup>
        <thead>
          <tr>
            <th>Date</th>
            <th>Artist</th>
            <th>Apps (Working / Idle)</th>
          </tr>
        </thead>
        <tbody>
          {filteredTableData.length > 0 ? (
            filteredTableData.map((record, index) => (
              <tr key={index}>
                <td data-label="Date">{record.date}</td>
                <td data-label="Artist">{record.username}</td>
                <td data-label="Apps">
                  <table className="app-subtable">
                    <thead>
                      <tr>
                        <th>App</th>
                        <th>App Type</th>
                        <th>Working</th>
                        <th>Idle</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortArtistApps(record.apps).map((app, i) => (
                        <tr key={i}>
                          <td data-label="App">{app.appName}</td>
                          <td data-label="Type">
                            {appMapping[app.appName] ? (
                              <span className={`app-type-badge ${appMapping[app.appName]}`}>
                                {appMapping[app.appName]}
                              </span>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td data-label="Working">{formatTime(app.workingTime)}</td>
                          <td data-label="Idle">{formatTime(app.idleTime)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3}>No tracking records found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );

  // --- Render Artist View ---
  const renderArtistView = () => (
    <>
      <div className="filter-display" style={{ textAlign: "center", marginBottom: "10px" }}>
        {getFilterDisplay()}
      </div>
      <div className="summary-boxes">
        <div className="summary-box">
          <h3>Work Time</h3>
          <p>{formatTime(overallWorkTime)}</p>
        </div>
        <div className="summary-box">
          <h3>Productive Time</h3>
          <p>{formatTime(overallProductiveTime)}</p>
        </div>
      </div>
      <div className="filter-toggle" style={{ textAlign: "center" }}>
        <button onClick={toggleDateFilter}>{showDateFilter ? "Filter –" : "Filter +"}</button>
        <button onClick={clearFilter} style={{ marginLeft: "10px" }}>
          Clear Filter
        </button>
      </div>
      {showDateFilter && (
        <div className="date-filter-container" ref={filterRef}>
          <div className="date-filter-inner" style={{ display: "flex" }}>
            <div className="date-pickers" style={{ flex: 1, paddingRight: "10px" }}>
              <label>
                Start Date:
                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              </label>
              <label>
                End Date:
                <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              </label>
            </div>
            <div className="preset-list" style={{ flex: 1 }}>
              <ul style={{ listStyle: "none", padding: 0 }}>
                <li onClick={() => handlePresetFilter("today")}>Today</li>
                <li onClick={() => handlePresetFilter("yesterday")}>Yesterday</li>
                <li onClick={() => handlePresetFilter("thisWeek")}>This Week</li>
                <li onClick={() => handlePresetFilter("last7Days")}>Last 7 Days</li>
                <li onClick={() => handlePresetFilter("previousWeek")}>Previous Week</li>
                <li onClick={() => handlePresetFilter("thisMonth")}>This Month</li>
                <li onClick={() => handlePresetFilter("previousMonth")}>Previous Month</li>
              </ul>
            </div>
          </div>
        </div>
      )}
      <div className="slider-container">
        <input
          type="radio"
          id="workTime"
          name="timeToggle"
          value="work"
          checked={timeMode === "work"}
          onChange={() => setTimeMode("work")}
        />
        <label htmlFor="workTime">Work Time</label>
        <input
          type="radio"
          id="productiveTime"
          name="timeToggle"
          value="productive"
          checked={timeMode === "productive"}
          onChange={() => setTimeMode("productive")}
        />
        <label htmlFor="productiveTime">Productive</label>
        <span className="slider"></span>
      </div>
      <div className="chart-container">
        <Bar data={barChartData} options={barChartOptions} />
      </div>
      <table className="tracker-table">
        <colgroup>
          <col style={{ width: "30%" }} />
          <col style={{ width: "70%" }} />
        </colgroup>
        <thead>
          <tr>
            <th>Date</th>
            <th>Apps (Working / Idle)</th>
          </tr>
        </thead>
        <tbody>
          {filteredTableData.length > 0 ? (
            filteredTableData.map((record, index) => (
              <tr key={index}>
                <td data-label="Date">{record.date}</td>
                <td data-label="Apps">
                  <table className="app-subtable">
                    <thead>
                      <tr>
                        <th>App</th>
                        <th>App Type</th>
                        <th>Working</th>
                        <th>Idle</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortArtistApps(record.apps).map((app, i) => (
                        <tr key={i}>
                          <td data-label="App">{app.appName}</td>
                          <td data-label="Type">
                            {appMapping[app.appName] ? (
                              <span className={`app-type-badge ${appMapping[app.appName]}`}>
                                {appMapping[app.appName]}
                              </span>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td data-label="Working">{formatTime(app.workingTime)}</td>
                          <td data-label="Idle">{formatTime(app.idleTime)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={2}>No tracking records found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );

  return (
    <div className="tracker-container">
      <NavigationBar />
      <ToastContainer />
      <h2 style={{ textAlign: "center", color: "#f6e05e" }}>Tracking Details</h2>
      {role === "admin" ? renderAdminView() : renderArtistView()}
    </div>
  );
};

export default Tracker;
