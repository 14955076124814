import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import ArtistDashboard from './components/ArtistDashboard';
import Attendance from './components/Attendance';
import PaySlip from './components/payslip';
import Leave from './components/Leave';
import AnnualReport from './components/AnnualReport';
import Sheet from './components/Sheet';
import Users from './components/Users';
import HolidayCalendar from './components/HolidayCalendar';
import Updates from './components/Updates';
import ProtectedRoute from './components/ProtectedRoute';
import ListPage from "./components/ListPage";
import AdminScreenshots from "./components/AdminScreenshots";
import Tracker from "./components/Tracker";  // <-- Import the Tracker component
import AppList from "./components/applist"; 

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/artist-dashboard"
          element={
            <ProtectedRoute allowedRoles={['artist']}>
              <ArtistDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/attendance"
          element={
            <ProtectedRoute allowedRoles={['admin', 'artist']}>
              <Attendance />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payslip"
          element={
            <ProtectedRoute allowedRoles={['admin', 'artist']}>
              <PaySlip />
            </ProtectedRoute>
          }
        />
        <Route
          path="/leave"
          element={
            <ProtectedRoute allowedRoles={['admin', 'artist']}>
              <Leave />
            </ProtectedRoute>
          }
        />
        <Route
          path="/annual"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <AnnualReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sheet"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <Sheet />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="/holiday-calendar"
          element={
            <ProtectedRoute allowedRoles={['admin', 'artist']}>
              <HolidayCalendar />
            </ProtectedRoute>
          }
        />
        <Route
          path="/list"
          element={
            <ProtectedRoute allowedRoles={['admin', 'artist']}>
              <ListPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/updates"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <Updates />
            </ProtectedRoute>
          }
        />
        <Route
          path="/screenshots"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <AdminScreenshots />
            </ProtectedRoute>
          }
        />
         <Route
          path="/applist"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <AppList />
            </ProtectedRoute>
          }
        />
        {/* New Tracker Route */}
        <Route
          path="/tracker"
          element={
            <ProtectedRoute allowedRoles={['admin', 'artist']}>
              <Tracker />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
