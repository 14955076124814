import React, { useState, useEffect, useMemo } from "react";
import "../css/ArtistDashboard.css";  // Ensure this path is correct
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Linkify from "react-linkify";
import ProjectDropzone from "./ProjectDropzone";
import NavigationBar from "./Navbar";
import { apiInstance, socket } from "../config/config";

const ArtistDashboard = () => {
  const [projects, setProjects] = useState([]);
  const [totalProjects, setTotalProjects] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [tempCompletions, setTempCompletions] = useState({});
  const [loading, setLoading] = useState(false);
  const pageLimit = 50; // Adjust page size as needed

  // Fetch all projects for the logged-in artist (API does not support search, so we fetch all)
  const fetchProjects = async (page = 1) => {
    const username = localStorage.getItem("username");
    setLoading(true);
    try {
      const response = await apiInstance.get(
        `/api/forArtist/${username}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // Assume the API returns an array of projects (since no pagination is supported by default)
      const fetchedProjects = response.data;
      setProjects(fetchedProjects);
      setTotalProjects(fetchedProjects.length);
      setCurrentPage(1);
    } catch (error) {
      console.error("Failed to fetch projects:", error);
      toast.error("Failed to fetch projects");
    }
    setLoading(false);
  };

  // Initial fetch on mount and listen for real-time updates
  useEffect(() => {
    fetchProjects();
    socket.on("projectUpdated", () => {
      fetchProjects();
    });
    return () => {
      socket.off("projectUpdated");
      socket.disconnect();
    };
  }, []);

  // Client-side filtering: Filter projects based on search term
  const filteredProjects = useMemo(() => {
    if (!searchTerm) return projects;
    return projects.filter((project) =>
      project.projectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [projects, searchTerm]);

  // Load more projects — if you implement pagination on the API, you can adjust this.
  // Since our API doesn't support pagination for forArtist, this example is omitted.
  // But if you later add pagination, you can use a similar function:
  // const loadMoreProjects = () => { ... };

  // Custom decorator for Linkify ensuring proper URL protocols
  const customComponentDecorator = (href, text, key) => {
    const normalizedHref = href.match(/^(https?:\/\/)/i) ? href : `http://${href}`;
    return (
      <a href={normalizedHref} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  };

  // Handle changes in the completion percentage input
  const handleCompletionChange = (projectId, value) => {
    setTempCompletions((prev) => ({ ...prev, [projectId]: value }));
  };

  // Decode Base64 email back to plain text
  const decodeEmail = (encodedEmail) => {
    return atob(encodedEmail);
  };

  // Update project completion percentage on the server
  const updateCompletion = async (projectId) => {
    const newPercentage = tempCompletions[projectId];
    if (newPercentage != null) {
      try {
        await apiInstance.post(`/api/projects/${projectId}/completion`, {
          completionPercentage: newPercentage,
        });
        setProjects((prevProjects) =>
          prevProjects.map((project) =>
            project._id === projectId
              ? { ...project, completionPercentage: newPercentage }
              : project
          )
        );
        toast.success("Completion updated successfully");
        setTempCompletions((prev) => {
          const newState = { ...prev };
          delete newState[projectId];
          return newState;
        });
      } catch (error) {
        console.error("Failed to update completion percentage:", error);
        toast.error("Failed to update completion percentage");
      }
    }
  };

  // Update project after a successful dropzone upload
  const handleUploadSuccess = (projectId, lastUploadedDate) => {
    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project._id === projectId
          ? { ...project, lastUploadedDate }
          : project
      )
    );
  };

  return (
    <div className="artist-dashboard-container">
      <NavigationBar />
      <ToastContainer />

      <div className="artist-search-and-table-container">
        {/* Search Input */}
        <input
          type="text"
          placeholder="Search projects..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="artist-search-input"
        />

        {/* Projects Table */}
        <div className="artist-table-container">
          <table className="artist-projects-table">
            <colgroup>
              <col /> {/* Project Name */}
              <col /> {/* Image Upload */}
              <col /> {/* Description */}
              <col /> {/* Start Date */}
              <col /> {/* End Date */}
              <col /> {/* Completion % */}
              <col /> {/* Last Update Sent */}
            </colgroup>
            <thead>
              <tr>
                <th>Project Name</th>
                <th>Image Upload</th>
                <th>Description</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Completion %</th>
                <th>Last Update Sent</th>
              </tr>
            </thead>
            <tbody>
              {filteredProjects.map((project) => (
                <tr key={project._id}>
                  <td data-label="Project Name:">{project.projectName}</td>
                  <td data-label="Image Upload:">
                    <ProjectDropzone
                      projectId={project._id}
                      onUploadSuccess={handleUploadSuccess}
                      projectEmail={decodeEmail(project.clientEmail)}
                      projectName={project.projectName}
                    />
                  </td>
                  <td data-label="Description:">
                    <Linkify componentDecorator={customComponentDecorator}>
                      <div style={{ whiteSpace: "pre-wrap" }}>{project.description}</div>
                    </Linkify>
                    {project.comments && project.comments.length > 0 && (
                      <ul className="artist-comments-list">
                        {project.comments.map((comment, index) => (
                          <li key={index} className="artist-comment-item">
                            <strong>Comment {index + 1}:</strong>
                            <br />
                            <Linkify componentDecorator={customComponentDecorator}>
                              <div style={{ whiteSpace: "pre-wrap" }}>{comment.text}</div>
                            </Linkify>
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                  <td data-label="Start Date:">
                    {new Date(project.startDate).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td data-label="End Date:">
                    {new Date(project.endDate).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td data-label="Completion %:">
                    <input
                      type="number"
                      value={
                        tempCompletions[project._id] !== undefined
                          ? tempCompletions[project._id]
                          : project.completionPercentage
                      }
                      onChange={(e) =>
                        handleCompletionChange(project._id, e.target.value)
                      }
                      min="0"
                      max="100"
                      className="artist-completion-input"
                    />
                    <button onClick={() => updateCompletion(project._id)} className="artist-update-button">
                      Update
                    </button>
                  </td>
                  <td data-label="Last Update Sent:">
                    {project.lastUploadedDate || "No info"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {loading && <p>Loading...</p>}
          {/* If you plan to add server-side pagination in the future, you can implement a Load More button */}
        </div>
      </div>
    </div>
  );
};

export default ArtistDashboard;
