import React, { useState, useEffect } from "react";
import "../css/sheet.css";
import * as XLSX from "xlsx";
import { apiInstance, socket } from "../config/config";
import NavigationBar from "./Navbar";

function Sheet() {
  const [invoices, setInvoices] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);
  const [artists, setArtists] = useState([]);
  const [filterYear, setFilterYear] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [loading, setLoading] = useState(false);
  const pageLimit = 50; // Adjust page size as needed

  // Fetch invoices using server-side filtering and pagination
  const fetchInvoices = async (pageNumber = 1) => {
    setLoading(true);
    try {
      const search = "half payment"; // default search term
      const queryParams = `?limit=${pageLimit}&page=${pageNumber}&search=${encodeURIComponent(search)}${
        filterYear ? `&year=${filterYear}` : ""
      }${filterMonth ? `&month=${filterMonth}` : ""}`;
      const response = await apiInstance.get(`/api/invoices${queryParams}`);
      if (pageNumber === 1) {
        setInvoices(response.data.invoices);
      } else {
        setInvoices((prev) => [...prev, ...response.data.invoices]);
      }
      setTotalCount(response.data.totalCount);
      // Set the total amount from the server (for all filtered invoices)
      setTotalAmount(response.data.totalAmount);
      setPage(pageNumber);
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
    setLoading(false);
  };

  // Fetch artists for the assign dropdown
  const fetchArtists = async () => {
    try {
      const response = await apiInstance.get("/api/auth/artists", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setArtists(response.data.map((artist) => artist.username));
    } catch (error) {
      console.error("Error fetching artists:", error);
    }
  };

  useEffect(() => {
    fetchArtists();
    fetchInvoices();
    socket.on("invoicePaid", () => {
      fetchInvoices(1);
    });
    return () => {
      socket.off("invoicePaid");
    };
  }, []);

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(invoices);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoices");
    XLSX.writeFile(workbook, "invoices.xlsx");
  };

  const handleFilter = () => {
    fetchInvoices(1);
  };

  const clearFilter = () => {
    setFilterYear("");
    setFilterMonth("");
    fetchInvoices(1);
  };

  const getYearsOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
    return years.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ));
  };

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthsOptions = monthNames.map((name, index) => (
    <option key={name} value={index + 1}>
      {name}
    </option>
  ));

  const handlePaymentTypeChange = async (invoiceId, paymentType) => {
    try {
      await apiInstance.post(`/api/invoices/${invoiceId}`, { paymentType });
      setInvoices((prev) =>
        prev.map((inv) => (inv._id === invoiceId ? { ...inv, paymentType } : inv))
      );
    } catch (error) {
      console.error("Error updating payment type:", error);
    }
  };

  const handleUpdateInvoice = async (invoiceId, updatedFields) => {
    try {
      await apiInstance.post(`/api/invoices/${invoiceId}`, updatedFields);
      setInvoices((prev) =>
        prev.map((inv) => (inv._id === invoiceId ? { ...inv, ...updatedFields } : inv))
      );
    } catch (error) {
      console.error("Error updating invoice:", error);
    }
  };

  return (
    <div className="admin-dashboard-container">
      <NavigationBar />
      <div className="sheet-table-container">
        <div className="sheet-filter">
          <select
            value={filterYear}
            onChange={(e) => setFilterYear(e.target.value)}
            className="sheet-filter-select"
          >
            <option value="">Select Year</option>
            {getYearsOptions()}
          </select>
          <select
            value={filterMonth}
            onChange={(e) => setFilterMonth(e.target.value)}
            className="sheet-filter-select"
          >
            <option value="">Select Month</option>
            {monthsOptions}
          </select>
          <button className="sheet-filter-btn" onClick={handleFilter}>
            Filter
          </button>
          <button className="clear-filter-btn" onClick={clearFilter}>
            Clear Filter
          </button>
        </div>
        <button className="export-btn" onClick={exportToExcel}>
          Export to Excel
        </button>
      </div>
      <table className="sheet-table">
        <thead>
          <tr>
            <th>Project Name</th>
            <th>Email</th>
            <th>Amount Paid</th>
            <th>Payment Type</th>
            <th>Project Status</th>
            <th>Assign</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>File Requested</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, index) => (
            <tr key={index}>
              <td>{invoice.projectName}</td>
              <td>{invoice.customerEmail}</td>
              <td>${invoice.amountearned / 100}</td>
              <td>
                <select
                  value={invoice.paymentType}
                  onChange={(e) => handlePaymentTypeChange(invoice._id, e.target.value)}
                  className={`payment-type-select ${
                    invoice.paymentType === "Half"
                      ? "red-background"
                      : invoice.paymentType === "Full"
                      ? "green-background"
                      : "refund-background"
                  }`}
                >
                  <option value="Half">Half</option>
                  <option value="Full">Full</option>
                  <option value="Refund">Refund</option>
                </select>
              </td>
              <td>
                <select
                  value={invoice.projectStatus}
                  onChange={(e) =>
                    handleUpdateInvoice(invoice._id, { projectStatus: e.target.value })
                  }
                  className={`custom-select ${
                    invoice.projectStatus === "Not Assigned"
                      ? "grey-background"
                      : invoice.projectStatus === "Work In Progress"
                      ? "red-background"
                      : "green-background"
                  }`}
                >
                  <option value="Not Assigned">NA</option>
                  <option value="Work In Progress">WIP</option>
                  <option value="Done">Done</option>
                </select>
              </td>
              <td>
                <select
                  name="assignedTo"
                  value={invoice.assign}
                  onChange={(e) =>
                    handleUpdateInvoice(invoice._id, { assign: e.target.value })
                  }
                  required
                  className={`custom-select ${
                    !invoice.assign || invoice.assign === "None" ? "grey-background" : "random-background"
                  }`}
                >
                  <option value="">Select</option>
                  {artists.map((artist, index) => (
                    <option key={index} value={artist}>
                      {artist}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                {new Date(invoice.startDate).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </td>
              <td>
                {new Date(invoice.endDate).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </td>
              <td>
                <select
                  value={invoice.fileRequested}
                  onChange={(e) =>
                    handleUpdateInvoice(invoice._id, { fileRequested: e.target.value })
                  }
                  className={`custom-select ${
                    invoice.fileRequested === "Null"
                      ? "grey-background"
                      : invoice.fileRequested === "Requested"
                      ? "red-background"
                      : "green-background"
                  }`}
                >
                  <option value="Null">Null</option>
                  <option value="Requested">Requested</option>
                  <option value="Delivered">Delivered</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="total-amount-sheet">
        <strong>Total Amount Paid: ${totalAmount / 100}</strong>
      </div>
      {loading && <p>Loading...</p>}
      {!loading && invoices.length < totalCount && (
        <button onClick={() => fetchInvoices(page + 1)} className="load-more-button">
          Load More Invoices
        </button>
      )}
    </div>
  );
}

export default Sheet;
