import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { apiInstance } from "../config/config";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";  


const ProjectDropzone = ({
  projectId,
  projectEmail,
  projectName,
  onUploadSuccess,
}) => {
  const [selectedFiles, setSelectedFiles] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleFilesAdded(acceptedFiles, projectId);
    },
    [projectId]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const handleFilesAdded = (acceptedFiles, projectId) => {
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [projectId]: acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    }));
  };

  const removeFile = (fileName, projectId, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [projectId]: prevFiles[projectId].filter((file) => file.name !== fileName),
    }));
  };

  const uploadFiles = async (e, projectId, projectEmail, projectName) => {
    e.preventDefault();
    e.stopPropagation();

    // Prevent multiple uploads if one is already in progress
    if (isUploading) return;

    const filesToUpload = selectedFiles[projectId] || [];
    if (filesToUpload.length === 0) {
      toast.error("No files to upload");
      return;
    }

    const formData = new FormData();
    filesToUpload.forEach((file) => {
      formData.append("files", file);
    });

    // Format current date/time
    const now = new Date();
    const formattedDate = `${now.getDate().toString().padStart(2, "0")}-${(
      now.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${now.getFullYear().toString().substr(-2)} ${now.getHours() %
      12 ||
      12}:${now.getMinutes().toString().padStart(2, "0")} ${now.getHours() >= 12 ? "PM" : "AM"}`;

    formData.append("lastUploadedDate", formattedDate);
    formData.append("projectId", projectId);
    formData.append("email", hashEmail(projectEmail));
    formData.append("projectName", projectName);
    formData.append("artist", localStorage.getItem("username"));

    try {
      setIsUploading(true);
      setUploadProgress(0);

      // Use onUploadProgress to track progress (works if using Axios)
      await apiInstance.post("/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded * 100) / total);
          setUploadProgress(percentCompleted);
        },
      });

      toast.success("All files uploaded successfully");

      // Clear the files from state
      setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [projectId]: [],
      }));

      if (onUploadSuccess) {
        onUploadSuccess(projectId, formattedDate);
      }
    } catch (error) {
      toast.error("Error uploading files");
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const hashEmail = (email) => {
    let hashedEmail = "";
    for (let i = 0; i < email.length; i++) {
      hashedEmail += email.charCodeAt(i).toString(16);
    }
    return hashedEmail;
  };

  // Retrieve files for the specific project
  const filesForProject = selectedFiles[projectId] || [];

  // If no files selected yet, show the dropzone
  if (!filesForProject.length) {
    return (
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </div>
    );
  }

  return (
    <div className="images-and-button">
      {/* Scrollable container for file previews */}
      <div className="images-preview-container">
        {filesForProject.map((file, index) => (
          <div key={index} className="image-preview-container">
            <img
              src={file.preview}
              alt={`preview-${index}`}
              className="image-preview"
            />
            <button
              onClick={(e) => removeFile(file.name, projectId, e)}
              className="remove-image-icon"
            >
              &times;
            </button>
          </div>
        ))}
      </div>

      {/* Show progress indicator if upload is in progress */}
      {isUploading && (
        <div className="upload-progress">
          <progress value={uploadProgress} max="100" />
          <span>{uploadProgress}%</span>
        </div>
      )}

      {/* Disable upload button while uploading */}
      <button
        onClick={(e) => uploadFiles(e, projectId, projectEmail, projectName)}
        className="upload-button"
        disabled={isUploading}
      >
        {isUploading ? "Uploading..." : "Upload"}
      </button>
    </div>
  );
};

export default ProjectDropzone;