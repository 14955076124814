// ListPage.jsx
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/ListPage.css";
import { apiInstance, socket } from "../config/config";
import NavigationBar from "./Navbar";

/**
 * Safely parse a date string. Returns a valid Date or null if invalid.
 */
function safeDate(date) {
  if (!date) return null;
  const d = new Date(date);
  return isNaN(d.getTime()) ? null : d;
}

/**
 * Format date as YYYY-MM-DD, or "" if invalid.
 */
function formatDateYYYYMMDD(date) {
  const d = safeDate(date);
  if (!d) return "";
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const day = d.getDate().toString().padStart(2, "0");
  return `${d.getFullYear()}-${month}-${day}`;
}

/**
 * Format date as DD/MM/YYYY, or "N/A" if invalid.
 */
function formatDateDDMMYYYY(date) {
  const d = safeDate(date);
  if (!d) return "N/A";
  const options = {
    timeZone: "Asia/Kolkata",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  return new Intl.DateTimeFormat("en-IN", options).format(d);
}

/**
 * Format date with toLocaleDateString, or "N/A" if invalid.
 */
function formatDate(date) {
  const d = safeDate(date);
  if (!d) return "N/A";
  return d.toLocaleDateString();
}

// Calculate today's Indian date in YYYY-MM-DD format for default filter values
const defaultIndianDate = new Date().toLocaleDateString("en-CA", {
  timeZone: "Asia/Kolkata",
});

// ================== ADMIN VIEW ==================
const AdminList = ({ refreshTrigger }) => {
  // State for adding new entries
  const [newEntries, setNewEntries] = useState([
    { projectName: "", projectType: "New", note: "" },
  ]);
  const [commonDate, setCommonDate] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [storedRecords, setStoredRecords] = useState([]);
  const [users, setUsers] = useState([]);

  // Initialize filter states with default Indian date (and "all" for artist)
  const [filterDateInput, setFilterDateInput] = useState(defaultIndianDate);
  const [filterArtistInput, setFilterArtistInput] = useState("");
  const [filterDate, setFilterDate] = useState(defaultIndianDate);
  const [filterArtist, setFilterArtist] = useState("");

  const [editingRecord, setEditingRecord] = useState(null);
  const [editForm, setEditForm] = useState({
    projectName: "",
    projectType: "",
    note: "",
  });
  const [groupAdd, setGroupAdd] = useState({});

  // Fetch records from API
  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await apiInstance.get("/api/listRecords", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        // Safely sort records (skip or handle invalid dates)
        const sortedRecords = response.data.sort((a, b) => {
          const da = safeDate(a.date);
          const db = safeDate(b.date);
          return (db ? db.getTime() : 0) - (da ? da.getTime() : 0);
        });
        setStoredRecords(sortedRecords);
      } catch (error) {
        console.error("Failed to fetch records:", error);
        toast.error("Failed to fetch records");
      }
    };
    fetchRecords();
  }, [refreshTrigger]);

  // Fetch users from API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await apiInstance.get("/api/users", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        setUsers(response.data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, []);

  // On mount, set the filter states to the default values.
  useEffect(() => {
    setFilterDate(filterDateInput);
    setFilterArtist(filterArtistInput);
  }, []);

  const handleAddRow = () => {
    if (newEntries.length < 10) {
      setNewEntries([...newEntries, { projectName: "", projectType: "New", note: "" }]);
    } else {
      toast.warn("Maximum 10 entries allowed");
    }
  };

  const handleEntryChange = (index, field, value) => {
    const updatedEntries = [...newEntries];
    updatedEntries[index][field] = value;
    setNewEntries(updatedEntries);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedUser) {
      toast.warn("Please select a user for this list");
      return;
    }
    const entriesPayload = newEntries.map((entry) => ({
      ...entry,
      ...(commonDate && { date: commonDate }),
    }));

    try {
      const response = await apiInstance.post(
        "/api/listRecords",
        { selectedUser, entries: entriesPayload },
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      );
      toast.success("Entries submitted successfully");
      setStoredRecords((prev) => [...prev, ...response.data.entries]);
      setNewEntries([{ projectName: "", projectType: "New", note: "" }]);
      setCommonDate("");
    } catch (error) {
      console.error("Failed to submit entries:", error);
      toast.error("Failed to submit entries");
    }
  };

  const applyFilter = () => {
    setFilterDate(filterDateInput);
    setFilterArtist(filterArtistInput);
  };

  const clearFilter = () => {
    setFilterDate("");
    setFilterArtist("");
    setFilterDateInput("");
    setFilterArtistInput("");
  };

  // Filtered records
  const filteredRecords = storedRecords.filter((record) => {
    // Compare with safe parse
    const recordDateYYYYMMDD = formatDateYYYYMMDD(record.date); 
    const dateMatch = filterDate ? recordDateYYYYMMDD === filterDate : true;
    const artistMatch = filterArtist ? record.artistName === filterArtist : true;
    return dateMatch && artistMatch;
  });

  // Group records by date and artist for display
  const groupMap = {};
  filteredRecords.forEach((record) => {
    const dateStr = formatDate(record.date); // "N/A" if invalid
    const key = `${dateStr}_${record.artistName}`;
    if (!groupMap[key]) {
      groupMap[key] = [];
    }
    groupMap[key].push(record);
  });

  const groups = Object.keys(groupMap).map((key) => {
    const [date, artistName] = key.split("_");
    return { key, date, artistName, projects: groupMap[key] };
  });

  const startEditing = (record) => {
    setEditingRecord(record._id);
    setEditForm({
      projectName: record.projectName,
      projectType: record.projectType,
      note: record.note,
    });
  };

  const cancelEditing = () => {
    setEditingRecord(null);
    setEditForm({ projectName: "", projectType: "", note: "" });
  };

  const saveEdit = async (recordId) => {
    try {
      const response = await apiInstance.put(
        `/api/listRecords/${recordId}`,
        editForm,
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      );
      toast.success("Record updated successfully");
      setStoredRecords((prev) =>
        prev.map((rec) => (rec._id === recordId ? response.data : rec))
      );
      cancelEditing();
    } catch (error) {
      console.error("Failed to update record:", error);
      toast.error("Failed to update record");
    }
  };

  const deleteRecord = async (recordId) => {
    try {
      await apiInstance.delete(`/api/listRecords/${recordId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      toast.success("Record deleted successfully");
      setStoredRecords((prev) => prev.filter((rec) => rec._id !== recordId));
    } catch (error) {
      console.error("Failed to delete record:", error);
      toast.error("Failed to delete record");
    }
  };

  const startGroupAdd = (groupKey) => {
    setGroupAdd((prev) => ({
      ...prev,
      [groupKey]: { projectName: "", projectType: "New", note: "" },
    }));
  };

  const handleGroupAddChange = (groupKey, field, value) => {
    setGroupAdd((prev) => ({
      ...prev,
      [groupKey]: { ...prev[groupKey], [field]: value },
    }));
  };

  const cancelGroupAdd = (groupKey) => {
    setGroupAdd((prev) => {
      const updated = { ...prev };
      delete updated[groupKey];
      return updated;
    });
  };

  const saveGroupAdd = async (groupKey, artistName, groupDate) => {
    try {
      const entry = groupAdd[groupKey];
      // Use safe parse or a fallback
      const safeParsedDate = safeDate(groupDate) || new Date(); 
      const payloadEntry = { ...entry, date: safeParsedDate };
      const response = await apiInstance.post(
        "/api/listRecords",
        { selectedUser: artistName, entries: [payloadEntry] },
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      );
      toast.success("Project added successfully");
      setStoredRecords((prev) => [...prev, ...response.data.entries]);
      cancelGroupAdd(groupKey);
    } catch (error) {
      console.error("Failed to add project:", error);
      toast.error("Failed to add project");
    }
  };

  return (
    <div className="list-page admin-list">
      <NavigationBar />
      <h2>Admin - New List Entries</h2>
      <div className="user-selection">
        <label>Select User: </label>
        <select
          className="dark-select"
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
          required
        >
          <option value="">Select User</option>
          {users.map((user, idx) => (
            <option key={idx} value={user.username}>
              {user.username}
            </option>
          ))}
        </select>
      </div>
      <div className="common-date">
        <label>Common Date: </label>
        <input
          type="date"
          className="dark-input"
          value={commonDate}
          onChange={(e) => setCommonDate(e.target.value)}
        />
      </div>
      <form onSubmit={handleSubmit} className="admin-form">
        {newEntries.map((entry, index) => (
          <div key={index} className="entry-row">
            <input
              type="text"
              className="dark-input"
              placeholder="Project Name"
              value={entry.projectName}
              onChange={(e) => handleEntryChange(index, "projectName", e.target.value)}
              required
            />
            <select
              className="dark-select"
              value={entry.projectType}
              onChange={(e) => handleEntryChange(index, "projectType", e.target.value)}
            >
              <option value="New">New</option>
              <option value="Cmt">Cmt</option>
              <option value="no cmt">no cmt</option>
            </select>
            <input
              type="text"
              className="dark-input"
              placeholder="Note"
              value={entry.note}
              onChange={(e) => handleEntryChange(index, "note", e.target.value)}
            />
          </div>
        ))}
        {newEntries.length < 10 && (
          <button type="button" onClick={handleAddRow} className="add-row-btn">
            +
          </button>
        )}
        <button type="submit" className="admin-submit-button">
          Submit
        </button>
      </form>
      <hr />
      <h2>Stored Records</h2>
      <div className="filter-section">
        <label>
          Date Filter:
          <input
            type="date"
            className="dark-input"
            value={filterDateInput}
            onChange={(e) => setFilterDateInput(e.target.value)}
          />
        </label>
        <label>
          Artist:
          <select
            className="dark-select"
            value={filterArtistInput}
            onChange={(e) => setFilterArtistInput(e.target.value)}
          >
            <option value="">All</option>
            {users.map((user, idx) => (
              <option key={idx} value={user.username}>
                {user.username}
              </option>
            ))}
          </select>
        </label>
        <div className="filter-buttons">
          <button type="button" className="action-btn" onClick={applyFilter}>
            Filter
          </button>
          <button type="button" className="action-btn" onClick={clearFilter}>
            Clear
          </button>
        </div>
      </div>
      {groups.length > 0 ? (
        <div className="records-table-wrap">
          <table className="records-table">
            <colgroup>
              <col /> {/* Date */}
              <col /> {/* Artist */}
              <col /> {/* Project Name */}
              <col /> {/* Project Type */}
              <col /> {/* Note */}
              <col /> {/* Action */}
            </colgroup>
            <thead>
              <tr>
                <th>Date</th>
                <th>Artist</th>
                <th>Project Name</th>
                <th>Project Type</th>
                <th>Note</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {groups.map((group, groupIdx) => (
                <React.Fragment key={group.key}>
                  {group.projects.map((proj, projIdx) => (
                    <tr
                      key={`${groupIdx}-${projIdx}`}
                      className={editingRecord === proj._id ? "editing-row" : ""}
                    >
                      {projIdx === 0 && (
                        <>
                          <td rowSpan={group.projects.length}>
                            {formatDateDDMMYYYY(proj.date)}
                          </td>
                          <td rowSpan={group.projects.length}>{proj.artistName}</td>
                        </>
                      )}
                      {editingRecord === proj._id ? (
                        <>
                          <td>
                            <div className="inline-form">
                              <input
                                type="text"
                                className="dark-input"
                                value={editForm.projectName}
                                onChange={(e) =>
                                  setEditForm((prev) => ({ ...prev, projectName: e.target.value }))
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="inline-form">
                              <select
                                className="dark-select"
                                value={editForm.projectType}
                                onChange={(e) =>
                                  setEditForm((prev) => ({ ...prev, projectType: e.target.value }))
                                }
                              >
                                <option value="New">New</option>
                                <option value="Cmt">Cmt</option>
                                <option value="no cmt">no cmt</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="inline-form">
                              <input
                                type="text"
                                className="dark-input"
                                value={editForm.note}
                                onChange={(e) =>
                                  setEditForm((prev) => ({ ...prev, note: e.target.value }))
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <button className="action-btn save-btn" onClick={() => saveEdit(proj._id)}>
                              Save
                            </button>
                            <button className="action-btn" onClick={cancelEditing}>
                              Cancel
                            </button>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{proj.projectName}</td>
                          <td>{proj.projectType}</td>
                          <td>{proj.note}</td>
                          <td>
                            <button className="action-btn" onClick={() => startEditing(proj)}>
                              Edit
                            </button>
                            <button
                              className="action-btn delete-btn"
                              onClick={() => deleteRecord(proj._id)}
                            >
                              Delete
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                  <tr className="add-more-row">
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      {groupAdd[group.key] ? (
                        <div className="inline-form">
                          <input
                            type="text"
                            className="dark-input"
                            placeholder="Project Name"
                            value={groupAdd[group.key].projectName}
                            onChange={(e) =>
                              handleGroupAddChange(group.key, "projectName", e.target.value)
                            }
                          />
                          <select
                            className="dark-select"
                            value={groupAdd[group.key].projectType}
                            onChange={(e) =>
                              handleGroupAddChange(group.key, "projectType", e.target.value)
                            }
                          >
                            <option value="New">New</option>
                            <option value="Cmt">Cmt</option>
                            <option value="no cmt">no cmt</option>
                          </select>
                          <input
                            type="text"
                            className="dark-input"
                            placeholder="Note"
                            value={groupAdd[group.key].note}
                            onChange={(e) =>
                              handleGroupAddChange(group.key, "note", e.target.value)
                            }
                          />
                          <button
                            className="action-btn save-btn"
                            onClick={() => saveGroupAdd(group.key, group.artistName, group.date)}
                          >
                            Save
                          </button>
                          <button className="action-btn" onClick={() => cancelGroupAdd(group.key)}>
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <button className="action-btn" onClick={() => startGroupAdd(group.key)}>
                          Add More Project
                        </button>
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No records found</p>
      )}
      <ToastContainer />
    </div>
  );
};

// ================== ARTIST VIEW ==================
const ArtistList = ({ refreshTrigger, notifications, removeNotification }) => {
  const [records, setRecords] = useState([]);
  // Initialize filter state with default Indian date
  const [filterDateInput, setFilterDateInput] = useState(defaultIndianDate);
  const [filterDate, setFilterDate] = useState(defaultIndianDate);

  // Fetch records from API
  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await apiInstance.get("/api/listRecords", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        const loggedInUser = localStorage.getItem("username");
        // Safely sort records
        const sortedRecords = response.data
          .filter((r) => r.artistName === loggedInUser)
          .sort((a, b) => {
            const da = safeDate(a.date);
            const db = safeDate(b.date);
            return (db ? db.getTime() : 0) - (da ? da.getTime() : 0);
          });
        setRecords(sortedRecords);
      } catch (error) {
        console.error("Failed to fetch records:", error);
        toast.error("Failed to fetch records");
      }
    };
    fetchRecords();
  }, [refreshTrigger]);

  // On mount, apply default filter values
  useEffect(() => {
    setFilterDate(filterDateInput);
  }, []);

  const applyFilter = () => {
    setFilterDate(filterDateInput);
  };

  const clearFilter = () => {
    setFilterDate("");
    setFilterDateInput("");
  };

  const filteredRecords = records.filter((record) => {
    const recordDateYYYYMMDD = formatDateYYYYMMDD(record.date);
    const dateMatch = filterDate ? recordDateYYYYMMDD === filterDate : true;
    return dateMatch;
  });

  // Group records by date and artist
  const groupMap = {};
  filteredRecords.forEach((record) => {
    const dateStr = formatDate(record.date); // "N/A" if invalid
    const key = `${dateStr}_${record.artistName}`;
    if (!groupMap[key]) {
      groupMap[key] = [];
    }
    groupMap[key].push(record);
  });
  const groups = Object.keys(groupMap).map((key) => {
    const [date, artistName] = key.split("_");
    return { date, artistName, projects: groupMap[key] };
  });

  return (
    <div className="list-page artist-list">
      <NavigationBar />
      <h2>List</h2>
      {/* Notification Panel for Artist */}
      <div className="notification-panel">
        <h3>Changes Notification</h3>
        {notifications.length > 0 ? (
          <div>
            <button
              className="clear-all-btn"
              onClick={async () => {
                try {
                  await apiInstance.delete(
                    `/api/notifications/artist/${localStorage.getItem("username")}`,
                    {
                      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                    }
                  );
                  removeNotification("all");
                } catch (err) {
                  console.error("Failed to clear notifications", err);
                }
              }}
            >
              Clear All
            </button>
            <ul>
              {notifications.map((note) => (
                <li key={note._id || note.id} className="notification-item">
                  <span>{note.message}</span>
                  <button
                    className="remove-note-btn"
                    onClick={async () => {
                      try {
                        await apiInstance.delete(`/api/notifications/${note._id || note.id}`, {
                          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                        });
                        removeNotification(note._id || note.id);
                      } catch (err) {
                        console.error("Failed to remove notification", err);
                      }
                    }}
                  >
                    ×
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <p>No notifications</p>
        )}
      </div>
      <div className="filter-section">
        <label>
          Date Filter:
          <input
            type="date"
            className="dark-input"
            value={filterDateInput}
            onChange={(e) => setFilterDateInput(e.target.value)}
          />
        </label>
        <div className="filter-buttons">
          <button type="button" className="action-btn" onClick={applyFilter}>
            Filter
          </button>
          <button type="button" className="action-btn" onClick={clearFilter}>
            Clear
          </button>
        </div>
      </div>
      {groups.length > 0 ? (
        <div className="records-table-wrap">
          <table className="records-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Artist</th>
                <th>Project Name</th>
                <th>Project Type</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {groups.map((group, groupIdx) => (
                <React.Fragment key={groupIdx}>
                  {group.projects.map((proj, projIdx) => (
                    <tr key={proj._id || `${groupIdx}-${projIdx}`}>
                      {projIdx === 0 && (
                        <>
                          <td rowSpan={group.projects.length}>
                            {formatDateDDMMYYYY(proj.date)}
                          </td>
                          <td rowSpan={group.projects.length}>{proj.artistName}</td>
                        </>
                      )}
                      <td>{proj.projectName}</td>
                      <td>{proj.projectType}</td>
                      <td>{proj.note}</td>
                    </tr>
                  ))}
                  <tr className="group-spacer">
                    <td colSpan="5">
                      <div className="spacer-div"></div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No record found</p>
      )}
      <ToastContainer />
    </div>
  );
};

// ================== MAIN COMPONENT ==================
const ListPage = () => {
  const role = localStorage.getItem("role");
  const [refreshTrigger, setRefreshTrigger] = useState(Date.now());
  const [notifications, setNotifications] = useState([]);
  const loggedInUser = localStorage.getItem("username");

  // Fetch persisted notifications from the server when the component mounts
  useEffect(() => {
    if (role === "artist") {
      const fetchNotifications = async () => {
        try {
          const res = await apiInstance.get(`/api/notifications/${loggedInUser}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          });
          setNotifications(res.data);
        } catch (err) {
          console.error("Failed to fetch notifications", err);
        }
      };
      fetchNotifications();
    }
  }, [role, loggedInUser]);

  // Socket event listener for new notifications
  useEffect(() => {
    socket.on("listUpdated", (data) => {
      console.log("List updated event received:", data);
      setRefreshTrigger(Date.now());

      if (role === "artist") {
        // Re-fetch notifications on update
        const fetchNotifications = async () => {
          try {
            const res = await apiInstance.get(`/api/notifications/${loggedInUser}`, {
              headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setNotifications(res.data);
          } catch (err) {
            console.error("Failed to fetch notifications", err);
          }
        };
        fetchNotifications();
      }
    });

    return () => {
      socket.off("listUpdated");
    };
  }, [role, loggedInUser]);

  // Function to remove a notification locally (after API call is done in ArtistList)
  const removeNotification = (id) => {
    if (id === "all") {
      setNotifications([]);
    } else {
      setNotifications((prev) =>
        prev.filter((note) => note._id !== id && note.id !== id)
      );
    }
  };

  return (
    <div className="list-page-container">
      {role === "admin" ? (
        <AdminList refreshTrigger={refreshTrigger} />
      ) : (
        <ArtistList
          refreshTrigger={refreshTrigger}
          notifications={notifications}
          removeNotification={removeNotification}
        />
      )}
    </div>
  );
};

export default ListPage;
