import axios from "axios";
import { jwtDecode } from "jwt-decode";
import io from "socket.io-client";

// Axios instance using your BASE_URL setup
const isLiveEnvironment = true;
const BASE_URL = isLiveEnvironment
  ? "https://spotautos-api.onrender.com"
  : "http://localhost:3001";

const apiInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

// Variables to manage refresh timing and concurrency
let refreshTimeout = null;
let isRefreshing = false;

// Exponential backoff variables for refresh failures
let backoffDelay = 1000; // start with 1 second
const maxBackoffDelay = 30000; // maximum 30 seconds

/**
 * Proactively refreshes the access token with exponential backoff.
 */
async function refreshAccessToken() {
  // Prevent concurrent refresh attempts
  if (isRefreshing) return;
  isRefreshing = true;

  try {
    const response = await axios.post(`${BASE_URL}/api/auth/refresh-token`, {
      refreshToken: localStorage.getItem("refreshToken"),
    });
    const { accessToken, refreshToken } = response.data;

    // Store new tokens
    localStorage.setItem("token", accessToken);
    localStorage.setItem("refreshToken", refreshToken);

    // Update axios default header
    apiInstance.defaults.headers.Authorization = `Bearer ${accessToken}`;

    // Reset backoff delay on success and reschedule refresh
    backoffDelay = 1000;
    scheduleTokenRefresh();
  } catch (error) {
    console.error("Refresh token failed:", error);
    // If the backoff delay is less than the maximum, retry after the delay.
    if (backoffDelay < maxBackoffDelay) {
      console.info(`Retrying token refresh in ${backoffDelay} ms.`);
      setTimeout(refreshAccessToken, backoffDelay);
      backoffDelay *= 2; // Double the delay for the next attempt
    } else {
      // If maximum backoff is reached, clear tokens and redirect to login.
      console.error("Max refresh attempts reached. Redirecting to login.");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location = "/login";
    }
  } finally {
    isRefreshing = false;
  }
}

/**
 * Schedules a token refresh based on the token's expiry.
 * Immediately refreshes if the delay is zero or negative.
 */
function scheduleTokenRefresh() {
  // Clear any existing timer
  if (refreshTimeout) clearTimeout(refreshTimeout);

  const token = localStorage.getItem("token");
  if (!token) return;

  try {
    const decoded = jwtDecode(token);
    if (!decoded.exp) {
      throw new Error("Token missing expiration (exp) claim");
    }
    const now = Date.now() / 1000; // current time in seconds
    let delay = (decoded.exp - now - 59) * 1000; // 59-second buffer

    // If the delay is zero or negative, refresh immediately
    if (delay <= 0) {
      delay = 0;
    }
    console.info(`Scheduling token refresh in ${delay} ms.`);
    refreshTimeout = setTimeout(refreshAccessToken, delay);
  } catch (error) {
    console.error("Error decoding token or scheduling refresh:", error);
    // If there's an error (malformed token, missing exp, etc.), refresh immediately
    refreshAccessToken();
  }
}

// Set up axios interceptor for 401 errors to catch unexpected expiration
apiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        await refreshAccessToken();
        originalRequest.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
        return apiInstance(originalRequest);
      } catch (refreshError) {
        console.error("Error in interceptor refresh:", refreshError);
        window.location = "/login";
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

// On app load, schedule token refresh if a token exists
if (localStorage.getItem("token")) {
  scheduleTokenRefresh();
}

const socket = io(BASE_URL);

export { apiInstance, scheduleTokenRefresh, socket };
