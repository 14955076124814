import React, { useState, useEffect } from "react";
import NavigationBar from "./Navbar";
import { ToastContainer, toast } from "react-toastify";
import { apiInstance } from "../config/config";
import "../css/applist.css";

const AppList = () => {
  const [appList, setAppList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  // Slider filter state: when searchTerm is empty this filter applies.
  const [appTypeFilter, setAppTypeFilter] = useState("neutral");

  useEffect(() => {
    fetchAppList();
  }, []);

  const fetchAppList = async () => {
    try {
      const response = await apiInstance.get("/api/applist", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setAppList(response.data);
    } catch (error) {
      console.error("Error fetching app list:", error);
      toast.error("Error fetching app list");
    }
  };

  const handleAppTypeChange = async (appId, newAppType) => {
    try {
      await apiInstance.put(
        `/api/applist/${appId}`,
        { appType: newAppType },
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      );
      toast.success("App type updated successfully");
      fetchAppList();
    } catch (error) {
      console.error("Error updating app type:", error);
      toast.error("Error updating app type");
    }
  };

  // First filter by search term
  const filteredBySearch = appList.filter((app) =>
    app.appName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // If search term exists, ignore slider filter; otherwise filter by appTypeFilter.
  const finalAppList = searchTerm
    ? filteredBySearch
    : filteredBySearch.filter((app) => app.appType === appTypeFilter);

  return (
    <div className="applist-container">
      <NavigationBar />
      <ToastContainer />
      <h2>App List</h2>
      {/* Search Input */}
      <div className="app-search">
        <input
          type="text"
          placeholder="Search apps..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* Slider Toggle for App Type Filter (applies only when search is empty) */}
      <div className="slider-container">
        <input
          type="radio"
          id="neutral"
          name="appTypeToggle"
          value="neutral"
          checked={appTypeFilter === "neutral"}
          onChange={() => setAppTypeFilter("neutral")}
        />
        <label htmlFor="neutral">Neutral</label>
        <input
          type="radio"
          id="productive"
          name="appTypeToggle"
          value="productive"
          checked={appTypeFilter === "productive"}
          onChange={() => setAppTypeFilter("productive")}
        />
        <label htmlFor="productive">Productive</label>
        <span className="slider"></span>
      </div>
      <table className="applist-table">
        <colgroup>
          <col style={{ width: "50%" }} />
          <col style={{ width: "50%" }} />
        </colgroup>
        <thead>
          <tr>
            <th>App Name</th>
            <th>App Type</th>
          </tr>
        </thead>
        <tbody>
          {finalAppList.length > 0 ? (
            finalAppList.map((app) => (
              <tr key={app._id}>
                <td>{app.appName}</td>
                <td>
                  <select
                    value={app.appType}
                    onChange={(e) => handleAppTypeChange(app._id, e.target.value)}
                    style={{
                      backgroundColor:
                        app.appType === "productive" ? "#4BC0C0" : "#cbd5e0",
                      color: app.appType === "productive" ? "#1a202c" : "#2d3748",
                      fontWeight: "bold",
                    }}
                  >
                    <option value="neutral">Neutral</option>
                    <option value="productive">Productive</option>
                  </select>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No apps found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AppList;
