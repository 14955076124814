import React, { useState, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import JSZip from "jszip";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { apiInstance } from "../config/config"; // Adjust this path
import "../css/FileUploadContainer.css";

function FileUpload({ projectId, onUploadSuccess }) { // Add projectId and onUploadSuccess props
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFileLinks, setUploadedFileLinks] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [hideDropzone, setHideDropzone] = useState(false);
  const fileInputRef = useRef();
  const [uploadProgress, setUploadProgress] = useState(0); // New state for tracking upload progress

  const truncateFileName = (name, maxLength = 10) => {
    if (name.length <= maxLength) return name;
    return `${name.substring(0, maxLength)}...`; // Truncate and add ellipsis
  };
  
  const onDrop = useCallback((acceptedFiles) => {
    const filesWithPreview = acceptedFiles.map((file) => ({
      file,
      id: URL.createObjectURL(file),
      name: truncateFileName(file.name, 10), // Truncate file name if too long
    }));
    setSelectedFiles((prevFiles) => [...prevFiles, ...filesWithPreview]);
    setIsUploading(false);
    setHideDropzone(false);
  }, []);
  

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleRemoveFile = (id) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    URL.revokeObjectURL(id);
  };

  const handleUpload = async () => {
    if (!selectedFiles.length) {
      toast.warn("Please select file(s) to upload.");
      return;
    }
    setIsUploading(true);
    setHideDropzone(true);
    setUploadProgress(0);

    const formData = new FormData();
    const zip = new JSZip();

    if (selectedFiles.length > 1) {
      selectedFiles.forEach((fileObj) => {
        zip.file(fileObj.file.name, fileObj.file);
      });
      const zipBlob = await zip.generateAsync({ type: "blob" });
      formData.append("files", zipBlob, "uploadedImages.zip");
    } else {
      formData.append("files", selectedFiles[0].file);
    }

    try {
      const response = await apiInstance.post("/api/file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
      toast.success("File(s) uploaded successfully!");
      const links = response.data.files.map((file) => ({
        location: file.location,
        name: file.originalname,
      }));
      setUploadedFileLinks(links);
      setSelectedFiles([]);
      
      // Notify ArtistDashboard of the successful upload
      if (onUploadSuccess) {
        onUploadSuccess(projectId, new Date().toISOString()); // Pass the projectId and current date as the last uploaded time
      }
    } catch (error) {
      console.error("Upload error:", error.response ? error.response.data : error);
      toast.error("Error uploading file(s).");
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };
  

  const handleReset = () => {
    setUploadedFileLinks([]);
    setSelectedFiles([]);
    setIsUploading(false);
    setHideDropzone(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div className="file-upload-container">
      {!hideDropzone && (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
      )}
      {selectedFiles.length > 0 && (
        <div className="selected-files-list">
          {selectedFiles.map((fileObj, index) => (
            <div key={fileObj.id} className="selected-file-item">
              {fileObj.file.name}
              <button
                onClick={() => handleRemoveFile(fileObj.id)}
                className="remove-file-btn"
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      )}
      {isUploading && (
        <div className="upload-progress">Uploading: {uploadProgress}%</div>
      )}

      {!isUploading && selectedFiles.length > 0 && (
        <button onClick={handleUpload} className="upload-button">
          Upload
        </button>
      )}
      {uploadedFileLinks.length > 0 && (
        <div className="uploaded-links-container">
          {uploadedFileLinks.map((link, index) => (
            <React.Fragment key={index}>
              <a
                href={link.location}
                target="_blank"
                rel="noopener noreferrer"
                className="uploaded-link"
              >
                {link.location}
              </a>
              <br />
              <CopyToClipboard
                text={link.location}
                onCopy={() =>
                  toast.success(`Link ${index + 1} copied to clipboard!`)
                }
              >
                <button className="copy-link-button">Copy Link</button>
              </CopyToClipboard>
            </React.Fragment>
          ))}
          <button onClick={handleReset} className="reset-link-button">
            Reset Links
          </button>
        </div>
      )}
    </div>
  );
}

export default FileUpload;