import React, { useState, useEffect } from "react";
import "../css/Annualreport.css";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import NavigationBar from "./Navbar";
import { apiInstance, socket } from "../config/config";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AnnualReport = () => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(""); // e.g. "February" or empty for all months
  const [invoices, setInvoices] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [chartData, setChartData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editInvoiceId, setEditInvoiceId] = useState(null);
  const [editLineItems, setEditLineItems] = useState([]);

  // Define years and months options
  const years = Array.from({ length: 13 }, (_, i) => currentYear + i - 10);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Fetch invoices from API (with filters passed to API)
  // Here, we fetch all invoices for the period by using a high limit.
  const fetchInvoices = async () => {
    try {
      const monthIndex = months.indexOf(selectedMonth) + 1; // if none selected, monthIndex will be 0
      let url = `/api/invoices?year=${selectedYear}&limit=10000`;
      if (monthIndex > 0) {
        url += `&month=${monthIndex}`;
      }
      // Pass empty search so that no additional line-item filtering is applied
      url += `&search=`;
      const response = await apiInstance.get(url);
      // API returns { invoices, totalCount, totalAmount }
      setInvoices(response.data.invoices);
      setTotalAmount(response.data.totalAmount);
      prepareChartData(response.data.invoices);
    } catch (error) {
      console.error(
        "Error fetching invoices:",
        error.response ? error.response.data : error
      );
    }
  };

  // Re-fetch invoices whenever selectedYear or selectedMonth changes.
  useEffect(() => {
    fetchInvoices();
    socket.on("invoicePaid", () => {
      fetchInvoices();
    });
    return () => {
      socket.off("invoicePaid");
    };
  }, [selectedYear, selectedMonth]);

  // Prepare chart data based on fetched invoices.
  const prepareChartData = (invoicesData) => {
    const monthIndex = months.indexOf(selectedMonth) + 1;
    if (monthIndex === 0) {
      setChartData({});
      return;
    }
    const currentMonthIndex = monthIndex;
    const previousMonthIndex = currentMonthIndex === 1 ? 12 : currentMonthIndex - 1;
    const yearOfPreviousMonth = currentMonthIndex === 1 ? selectedYear - 1 : selectedYear;

    const generateAllDatesForMonth = (year, month) => {
      const dates = [];
      const date = new Date(year, month - 1, 1);
      while (date.getMonth() === month - 1) {
        dates.push(
          `${year}-${String(month).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`
        );
        date.setDate(date.getDate() + 1);
      }
      return dates;
    };

    const allCurrentMonthDates = generateAllDatesForMonth(selectedYear, currentMonthIndex);
    const allPreviousMonthDates = generateAllDatesForMonth(yearOfPreviousMonth, previousMonthIndex);

    const currentMonthTotals = allCurrentMonthDates.reduce(
      (acc, date) => ({ ...acc, [date]: 0 }),
      {}
    );
    const previousMonthTotals = allPreviousMonthDates.reduce(
      (acc, date) => ({ ...acc, [date]: 0 }),
      {}
    );

    invoicesData.forEach((invoice) => {
      const invoiceDate = new Date(invoice.startDate);
      const dateKey = `${invoiceDate.getFullYear()}-${String(
        invoiceDate.getMonth() + 1
      ).padStart(2, "0")}-${String(invoiceDate.getDate()).padStart(2, "0")}`;
      if (currentMonthTotals.hasOwnProperty(dateKey)) {
        currentMonthTotals[dateKey] += invoice.amountearned;
      }
      if (previousMonthTotals.hasOwnProperty(dateKey)) {
        previousMonthTotals[dateKey] += invoice.amountearned;
      }
    });

    const currentMonthData = allCurrentMonthDates.map(
      (date) => currentMonthTotals[date] / 100
    );
    const previousMonthData = allPreviousMonthDates.map(
      (date) => previousMonthTotals[date] / 100
    );
    const labels = allCurrentMonthDates.map((date) =>
      new Date(date).toLocaleDateString()
    );

    setChartData({
      labels,
      datasets: [
        {
          label: "Previous Month",
          data: previousMonthData,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.2)",
        },
        {
          label: "Selected Month",
          data: currentMonthData,
          borderColor: "rgb(54, 162, 235)",
          backgroundColor: "rgba(54, 162, 235, 0.2)",
        },
      ],
    });
  };

  // Inline editing functions for invoice line items
  const updateLineItems = async (invoiceId, lineItems) => {
    try {
      await apiInstance.post("/api/invoices/updateLineItems", {
        invoiceId,
        lineItems,
      });
      console.log("Update successful");
      // After saving, refresh the invoice list
      fetchInvoices();
      setIsEditing(false);
      setEditInvoiceId(null);
      setEditLineItems([]);
    } catch (error) {
      console.error(
        "Error updating line items:",
        error.response ? error.response.data : error
      );
    }
  };

  const handleEditInvoice = (invoice) => {
    setIsEditing(true);
    setEditInvoiceId(invoice._id);
    // Create a copy of the current line items for editing
    setEditLineItems([...invoice.lineItems]);
  };

  const cancelEdit = () => {
    setIsEditing(false);
    setEditInvoiceId(null);
    setEditLineItems([]);
  };

  return (
    <div className="annual-report-container">
      <NavigationBar />

      <div className="annual-selection-container">
        <label>
          <select
            className="annual-selection-select"
            value={selectedYear}
            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </label>
        <label>
          <select
            className="annual-selection-select"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            <option value="">All Months</option>
            {months.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
        </label>
      </div>

      {invoices.length > 0 ? (
        <>
          <table className="annual-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Amount Paid</th>
                <th>Description</th>
                <th>Start Date</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice, index) => (
                <tr key={invoice._id || index}>
                  <td>{invoice.projectName}</td>
                  <td>{invoice.customerEmail}</td>
                  <td>${invoice.amountPaid / 100}</td>
                  <td>
                    <div className="inline-edit-container">
                      <div>
                        {isEditing && editInvoiceId === invoice._id ? (
                          <ul>
                            {editLineItems.map((item, itemIndex) => (
                              <li key={itemIndex}>
                                <input
                                  type="text"
                                  value={item.description}
                                  onChange={(e) => {
                                    const newLineItems = [...editLineItems];
                                    newLineItems[itemIndex].description = e.target.value;
                                    setEditLineItems(newLineItems);
                                  }}
                                />
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <ul>
                            {invoice.lineItems.map((item, itemIndex) => (
                              <li key={itemIndex}>{item.description}</li>
                            ))}
                          </ul>
                        )}
                      </div>
                      <div className="btn-container">
                        {isEditing && editInvoiceId === invoice._id ? (
                          <>
                            <button
                              onClick={() =>
                                updateLineItems(editInvoiceId, editLineItems)
                              }
                              className="btn btn-save"
                            >
                              Save
                            </button>
                            <button onClick={cancelEdit} className="btn btn-cancel">
                              Cancel
                            </button>
                          </>
                        ) : (
                          <button
                            onClick={() => handleEditInvoice(invoice)}
                            className="btn btn-edit"
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>{invoice.startDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="annual-total-amount">
            <strong>Total Amount Paid: ${totalAmount / 100}</strong>
          </div>
        </>
      ) : (
        <p>No invoices found for this period.</p>
      )}

      <div className="annual-chart-container">
        {chartData &&
        chartData.labels &&
        chartData.datasets &&
        chartData.datasets.length > 0 ? (
          <Line data={chartData} options={{ maintainAspectRatio: false }} />
        ) : (
          <p>No data available for the chart.</p>
        )}
      </div>
    </div>
  );
};

export default AnnualReport;
