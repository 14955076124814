import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import NavigationBar from "./Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/AdminScreenshots.css";
import { apiInstance, socket } from "../config/config";

// Modal component using React Portal
const Modal = ({ children, onClose }) => {
  const modalRoot = document.getElementById("modal-root") || document.body;
  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>,
    modalRoot
  );
};

const AdminScreenshots = () => {
  const [screenshots, setScreenshots] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    date: "",
    artist: "",
    appName: ""
  });
  const [filteredScreenshots, setFilteredScreenshots] = useState([]);
  const [selectedScreenshot, setSelectedScreenshot] = useState(null);
  const [duration, setDuration] = useState("10");

  // Refs for the magnifier
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const lensRef = useRef(null);

  // Zoom factor for magnifier
  const zoom = 2;

  // API: Fetch screenshot duration
  const fetchDuration = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await apiInstance.get("/api/screenshot-duration", {
        headers: { Authorization: `Bearer ${token}` }
      });
      setDuration(response.data.duration.toString());
    } catch (error) {
      console.error("Error fetching screenshot duration:", error);
      toast.error("Failed to fetch screenshot duration");
    }
  };

  // API: Fetch paginated screenshots, with optional date filter.
  const fetchScreenshots = async (pageNumber = 1) => {
    const token = localStorage.getItem("token");
    try {
      let url = `/api/screenshots?limit=100&page=${pageNumber}`;
      if (filters.date) {
        url += `&date=${filters.date}`; // Expecting "YYYY-MM-DD"
      }
      // (Optional: Add filters for artist or appName if supported by your API)
      const response = await apiInstance.get(url, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (pageNumber === 1) {
        setScreenshots(response.data.screenshots);
      } else {
        setScreenshots(prev => [...prev, ...response.data.screenshots]);
      }
      setTotalCount(response.data.totalCount);
    } catch (error) {
      console.error("Error fetching screenshots:", error);
      toast.error("Failed to fetch screenshots");
    }
  };

  // Initially fetch duration and first page of screenshots.
  useEffect(() => {
    fetchDuration();
    fetchScreenshots();
  }, []);

  // When the date filter changes, refetch screenshots (reset to page 1).
  useEffect(() => {
    setPage(1);
    fetchScreenshots(1);
  }, [filters.date]);

  // For artist and appName filters, do client-side filtering on loaded screenshots.
  useEffect(() => {
    let filtered = screenshots;
    if (filters.artist) {
      filtered = filtered.filter((s) =>
        s.artistName.toLowerCase() === filters.artist.toLowerCase()
      );
    }
    if (filters.appName) {
      filtered = filtered.filter((s) =>
        s.appName.toLowerCase() === filters.appName.toLowerCase()
      );
    }
    setFilteredScreenshots(filtered);
  }, [filters.artist, filters.appName, screenshots]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  // Clear filter button: reset all filters and refetch screenshots.
  const handleClearFilters = () => {
    setFilters({ date: "", artist: "", appName: "" });
    setPage(1);
    fetchScreenshots(1);
  };

  // Magnifier Handlers (unchanged)
  const handleMouseEnter = () => {
    if (lensRef.current && imageRef.current) {
      lensRef.current.style.display = "block";
      lensRef.current.style.width = "300px";
      lensRef.current.style.height = "300px";
      const naturalWidth = imageRef.current.naturalWidth;
      const naturalHeight = imageRef.current.naturalHeight;
      lensRef.current.style.backgroundImage = `url(${imageRef.current.src})`;
      lensRef.current.style.backgroundSize =
        `${naturalWidth * zoom}px ${naturalHeight * zoom}px`;
    }
  };

  const handleMouseLeave = () => {
    if (lensRef.current) {
      lensRef.current.style.display = "none";
    }
  };

  const handleMouseMove = (e) => {
    if (!imageRef.current || !lensRef.current) return;

    const image = imageRef.current;
    const lens = lensRef.current;
    const rect = image.getBoundingClientRect();

    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (x < 0 || y < 0 || x > rect.width || y > rect.height) {
      lens.style.display = "none";
      return;
    }

    lens.style.display = "block";

    const ratioX = image.naturalWidth / rect.width;
    const ratioY = image.naturalHeight / rect.height;
    const lensWidth = lens.offsetWidth;
    const lensHeight = lens.offsetHeight;

    lens.style.left = image.offsetLeft + (x - lensWidth / 2) + "px";
    lens.style.top = image.offsetTop + (y - lensHeight / 2) + "px";

    let clampX = Math.max(0, Math.min(x, rect.width));
    let clampY = Math.max(0, Math.min(y, rect.height));

    const backgroundPosX = -((clampX * ratioX * zoom) - lensWidth / 2);
    const backgroundPosY = -((clampY * ratioY * zoom) - lensHeight / 2);

    lens.style.backgroundPosition = `${backgroundPosX}px ${backgroundPosY}px`;
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    try {
      await apiInstance.delete(`/api/screenshots/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success("Screenshot deleted successfully!");
      fetchScreenshots(1);
      setSelectedScreenshot(null);
    } catch (error) {
      console.error("Error deleting screenshot:", error);
      toast.error("Failed to delete screenshot");
    }
  };

  const formatToIndianTime = (timestampString) => {
    const parts = timestampString.split(" ");
    if (parts.length !== 2) return "Invalid date";
    const [datePart, timePart] = parts;
    const dateParts = datePart.split("/");
    if (dateParts.length !== 3) return "Invalid date";
    const [day, month, year] = dateParts;
    const isoString = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}T${timePart}:00`;
    const dateObj = new Date(isoString);
    if (isNaN(dateObj.getTime())) return "Invalid date";
    return dateObj.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  };

  const uniqueArtists = [...new Set(screenshots.map(s => s.artistName))];
  const uniqueApps = [...new Set(screenshots.map(s => s.appName))];

  const openModal = (screenshot) => {
    setSelectedScreenshot(screenshot);
  };

  const closeModal = () => {
    setSelectedScreenshot(null);
  };

  // Real-time update: Listen for "newScreenshot" events
  useEffect(() => {
    socket.on("newScreenshot", (newScreenshot) => {
      setScreenshots(prev => [newScreenshot, ...prev]);
    });
    return () => {
      socket.off("newScreenshot");
    };
  }, []);

  // Load more screenshots if available
  const loadMoreScreenshots = () => {
    const nextPage = page + 1;
    fetchScreenshots(nextPage);
    setPage(nextPage);
  };

  // Handle duration submit (unchanged)
  const handleDurationSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    try {
      await apiInstance.put(
        "/api/screenshot-duration",
        { duration: Number(duration) },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success("Screenshot duration updated!");
      fetchDuration();
    } catch (error) {
      console.error("Error updating duration:", error);
      toast.error("Failed to update screenshot duration");
    }
  };

  return (
    <div className="admin-screenshots-container">
      <NavigationBar />
      <ToastContainer />
      <h2>Artist Screenshots</h2>
      <div className="current-duration">
        <p>Current screenshot interval: {duration} sec</p>
      </div>
      <form className="duration-form" onSubmit={handleDurationSubmit}>
        <label htmlFor="duration">Set Screenshot Interval:</label>
        <select
          id="duration"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
        >
          <option value="10">10 sec</option>
          <option value="30">30 sec</option>
          <option value="60">60 sec</option>
          <option value="300">300 sec</option>
        </select>
        <button type="submit">Submit</button>
      </form>
      <div className="filters">
        <input
          type="date"
          name="date"
          value={filters.date}
          onChange={handleFilterChange}
        />
        <select name="artist" value={filters.artist} onChange={handleFilterChange}>
          <option value="">All Artists</option>
          {uniqueArtists.map((artist, index) => (
            <option key={index} value={artist}>
              {artist}
            </option>
          ))}
        </select>
        <select name="appName" value={filters.appName} onChange={handleFilterChange}>
          <option value="">All Apps</option>
          {uniqueApps.map((app, index) => (
            <option key={index} value={app}>
              {app}
            </option>
          ))}
        </select>
        <button className="clear-filter-btn" onClick={handleClearFilters}>
          Clear Filter
        </button>
      </div>
      <div className="screenshots-grid">
        {filteredScreenshots.map((screenshot) => (
          <div key={screenshot._id} className="screenshot-card">
            <img
              src={screenshot.imageUrl}
              alt={screenshot.appName}
              className="screenshot-image"
              onClick={() => openModal(screenshot)}
            />
            <div className="screenshot-info">
              <h4>{screenshot.appName}</h4>
              <p>{formatToIndianTime(screenshot.timestamp)}</p>
              <p>{screenshot.artistName}</p>
            </div>
          </div>
        ))}
        {filteredScreenshots.length === 0 && (
          <p>No screenshots found for the applied filters.</p>
        )}
      </div>
      {screenshots.length < totalCount && (
        <button onClick={loadMoreScreenshots} className="load-more-button">
          Load More Screenshots
        </button>
      )}
      {selectedScreenshot && (
        <Modal onClose={closeModal}>
          <div
            className="magnifier-container"
            ref={containerRef}
            onMouseMove={handleMouseMove}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={selectedScreenshot.imageUrl}
              alt="Enlarged screenshot"
              className="modal-image"
              ref={imageRef}
            />
            <div className="magnifier-lens" ref={lensRef}></div>
            <div className="popup-actions">
              <a
                href={selectedScreenshot.imageUrl}
                download
                className="action-button download-button"
              >
                ⤓
              </a>
              <button
                className="action-button delete-button"
                onClick={() => handleDelete(selectedScreenshot._id)}
              >
                🗑️
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AdminScreenshots;
