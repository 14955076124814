import React, { useState, useEffect } from "react";
import NavigationBar from "./Navbar"; // Adjust the import path according to your file structure
import "../css/Users.css"; // Import the CSS for styling
import { apiInstance} from "../config/config"; // Adjust the import path according to your project structure


const User = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [users, setUsers] = useState([]);


  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await apiInstance.get("/api/users");
        setUsers(data); // Update the state with the fetched users
      } catch (error) {
        alert("Failed to fetch users");
      }
    };

    fetchUser();
  }, []); // The empty array ensures this effect runs only once after the initial render

  const createUser = async () => {
    try {
      const { data } = await apiInstance.post("/api/users", { username, password, role });
      alert("User added successfully");
      setUsers([...users, data]); // Add the new user to the local state
    } catch (error) {
      alert("Failed to add user");
    }
  };

  const deleteUser = async (userId) => {
    try {
      await apiInstance.delete(`/api/users/${userId}`);
      setUsers(users.filter(user => user._id !== userId));
      alert("User deleted successfully");
    } catch (error) {
      alert("Failed to delete user");
    }
  };


  return (
    <div className="user-dashboard-container">
      <NavigationBar />
      <div className="user-form">
        <h3>Add New User</h3>
        <input
          className="input"
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          className="input"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <select
          className="select"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        >
          <option value="">Select a role</option>
          <option value="admin">Admin</option>
          <option value="artist">Artist</option>
        </select>
        <button className="user-submit-button" onClick={createUser}>
          Add User
        </button>
      </div>

      <div className="user-list">
        <h3>All Users</h3>
        {users.length > 0 && (
          <table className="user-table">
            <thead>
              <tr>
                <th>User</th>
                <th>Role</th>
                <th>Action</th> {/* Add action column */}
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.username}</td>
                  <td>{user.role}</td>
                  <td>
                    <button onClick={() => deleteUser(user._id)}>Delete</button>
                  </td> {/* Add delete button */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};


export default User;
