// src/components/Updates.js
import React, { useEffect, useState, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import { apiInstance, socket } from "../config/config";
import NavigationBar from "./Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import Slider from "react-slick";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "../css/Updates.css";

// Import slick-carousel CSS files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Helper function to format a date as DD/MM/YYYY
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

Modal.setAppElement("#root");

const Updates = () => {
  const [updates, setUpdates] = useState([]);
  const [artists, setArtists] = useState([]);
  const [filterArtist, setFilterArtist] = useState("All");
  const [filterDate, setFilterDate] = useState("");
  const [appliedFilters, setAppliedFilters] = useState({ artist: "All", date: "" });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const [zoom, setZoom] = useState(1);

  // Fetch all updates
  const fetchUpdates = async () => {
    try {
      const response = await apiInstance.get("/api/updates", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setUpdates(response.data);
    } catch (error) {
      console.error("Error fetching updates:", error);
    }
  };

  // Fetch all artists
  const fetchArtists = async () => {
    try {
      const response = await apiInstance.get("/api/users", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const artistUsers = response.data.filter((user) => user.role === "artist");
      setArtists(artistUsers);
    } catch (error) {
      console.error("Error fetching artists:", error);
    }
  };

  useEffect(() => {
    fetchUpdates();
    fetchArtists();
  }, []);

  // Listen for updateCreated events for realtime updates
  useEffect(() => {
    socket.on("updateCreated", fetchUpdates);
    return () => {
      socket.off("updateCreated", fetchUpdates);
    };
  }, []);

  // Memoized artist options for the filter dropdown
  const artistOptions = useMemo(() => {
    return ["All", ...artists.map((artist) => artist.username)];
  }, [artists]);

  // Filter updates based on applied filters
  const filteredUpdates = useMemo(() => {
    return updates.filter((update) => {
      const matchesArtist =
        appliedFilters.artist === "All" ||
        update.artist.toLowerCase() === appliedFilters.artist.toLowerCase();

      const updateDate = new Date(update.date).toISOString().split("T")[0];
      const matchesDate = appliedFilters.date ? updateDate === appliedFilters.date : true;

      return matchesArtist && matchesDate;
    });
  }, [updates, appliedFilters]);

  const handleApplyFilters = () => {
    setAppliedFilters({ artist: filterArtist, date: filterDate });
  };

  const handleResetFilters = () => {
    setFilterArtist("All");
    setFilterDate("");
    setAppliedFilters({ artist: "All", date: "" });
  };

  // Open modal with slider and reset zoom
  const openModalWithImages = (images) => {
    setCurrentImages(images);
    setZoom(1);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Slider settings – disable swipe/draggable for consistency with zoom/pan
  const sliderSettings = {
    dots: true,
    infinite: currentImages.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: currentImages.length > 1,
    swipe: false,
    draggable: false,
    arrows: true,
    afterChange: () => setZoom(1),
  };

  // Magnifier Handlers
  const handleMouseEnter = () => {
    if (lensRef.current && imageRef.current) {
      lensRef.current.style.display = "block";
      lensRef.current.style.width = "300px";
      lensRef.current.style.height = "300px";
      const naturalWidth = imageRef.current.naturalWidth;
      const naturalHeight = imageRef.current.naturalHeight;
      lensRef.current.style.backgroundImage = `url(${imageRef.current.src})`;
      lensRef.current.style.backgroundSize = `${naturalWidth * zoom}px ${naturalHeight * zoom}px`;
    }
  };

  const handleMouseLeave = () => {
    if (lensRef.current) {
      lensRef.current.style.display = "none";
    }
  };

  const handleMouseMove = (e) => {
    if (!imageRef.current || !lensRef.current) return;

    const image = imageRef.current;
    const lens = lensRef.current;
    const rect = image.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (x < 0 || y < 0 || x > rect.width || y > rect.height) {
      lens.style.display = "none";
      return;
    }
    lens.style.display = "block";

    const ratioX = image.naturalWidth / rect.width;
    const ratioY = image.naturalHeight / rect.height;
    const lensWidth = lens.offsetWidth;
    const lensHeight = lens.offsetHeight;

    lens.style.left = image.offsetLeft + (x - lensWidth / 2) + "px";
    lens.style.top = image.offsetTop + (y - lensHeight / 2) + "px";

    let clampX = Math.max(0, Math.min(x, rect.width));
    let clampY = Math.max(0, Math.min(y, rect.height));

    const backgroundPosX = -((clampX * ratioX * zoom) - lensWidth / 2);
    const backgroundPosY = -((clampY * ratioY * zoom) - lensHeight / 2);

    lens.style.backgroundPosition = `${backgroundPosX}px ${backgroundPosY}px`;
  };

  // Refs for the image and lens
  const imageRef = useRef(null);
  const lensRef = useRef(null);

  // Download button is added below each image in the modal slider via an <a> tag with download attribute.

  return (
    <div className="updates-container">
      <NavigationBar />

      <div className="updates-content">
        <h2>Project Updates</h2>

        {/* Filters */}
        <div className="filters-container">
          <div className="filter-item">
            <label htmlFor="artistFilter">Artist:</label>
            <select
              id="artistFilter"
              value={filterArtist}
              onChange={(e) => setFilterArtist(e.target.value)}
            >
              {artistOptions.map((artist, index) => (
                <option key={index} value={artist}>
                  {artist}
                </option>
              ))}
            </select>
          </div>

          <div className="filter-item">
            <label htmlFor="dateFilter">Date:</label>
            <input
              type="date"
              id="dateFilter"
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
            />
          </div>

          <div className="filter-buttons">
            <button onClick={handleApplyFilters}>Filter</button>
            <button onClick={handleResetFilters}>Reset</button>
          </div>
        </div>

        {/* Card Grid */}
        <div className="updates-list">
          {filteredUpdates.length > 0 ? (
            filteredUpdates.map((update) => (
              <div className="update-card" key={update._id}>
                <div className="update-card-inner">
                  <div className="update-card-top">
                    <span className="update-card-date">{formatDate(update.date)}</span>
                    <span className="update-card-artist">{update.artist}</span>
                  </div>
                  <div className="update-card-image">
                    {update.images && update.images.length > 0 ? (
                      <img
                        src={update.images[0]}
                        alt="update-first"
                        onClick={() => openModalWithImages(update.images)}
                      />
                    ) : (
                      <div className="no-image">No Image</div>
                    )}
                  </div>
                  <div className="update-card-bottom">
                    <span className="update-card-project">{update.projectName}</span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="no-updates">No updates found for the applied filters.</p>
          )}
        </div>
      </div>

      {/* Modal with Slider, Zoom/Pan, and Download Button */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Preview"
        className="image-modal"
        overlayClassName="image-modal-overlay"
      >
        <div className="modal-header">
          {/* Download button uses the first image URL as the download source */}
          <a href={currentImages[0]} download className="download-button">
            Download
          </a>
          <button onClick={closeModal} className="modal-close-button">
            Close
          </button>
        </div>
        <Slider {...sliderSettings}>
          {currentImages.map((url, index) => (
            <div key={index} className="slider-image-container">
              <TransformWrapper
                onZoomChange={(ref) => setZoom(ref.state.scale)}
                doubleClick={{ disabled: false, step: 2 }}
                wheel={{ step: 50 }}
                pinch={{ step: 5 }}
                initialScale={1}
                minScale={1}
                maxScale={3}
              >
                <TransformComponent>
                  <img src={url} alt={`slider-${index}`} className="modal-image" ref={imageRef} />
                </TransformComponent>
              </TransformWrapper>
            </div>
          ))}
        </Slider>
        <div className="magnifier-lens" ref={lensRef}></div>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default Updates;
